<template>
    <main class="flex flex-col px-8 py-24 md:flex-row md:px-32">
        <div class="w-32">
            <h2 class="text-black standard-subheading">
                PRIVACY POLICY
            </h2>
        </div>

        <div class="w-full pt-8 pr-8 md:pl-32 md:pt-0">
            <p>
                This Gordon L. Diewert Foundation, ("Foundation") Privacy Policy ("Policy") describes how the Foundation  and its licensees, subsidiaries, cause marketing websites and affiliated companies ("Diewert ") collects, uses and discloses personal information of customers through our Canadian websites and online services, including www.diewert.org and other FOUNDATION owned or operated websites and online services that link to this policy (collectively, the “Sites”), through our programs and in the physical stores of our Licensees ("Services"). This Policy applies to the DIEWERT mobile app and related sites and does not apply to websites and services (including DIEWERT websites and services) that display or link to different privacy statements. Websites and services co-branded with other partners may display or link to a joint privacy statement or separate privacy statements of our partners in addition to our Policy. For the purposes of this Policy, “Personal Information", means information that can be used to identify you individually, such as your name and address.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Information We Collect
            </h3>

            <p>
                As you use our services, we collect certain information about you and the services you use. The information we collect falls into three different categories: (1) information you give us; (2) information we collect from you automatically when you use our services; and (3) information we collect from other sources.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Information You Give Us
            </h3>

            <p>
                We collect information you give us when you use our services. Some examples of using our "services" are when you visit one of the stores of our licensees, visit one of our websites, create an mobile loyalty and gift card account, buy a gift card online, or participate in a survey or promotion, transact in the cause marketing marketplace & websites and related web stores. The information you give us may include your first or last name, username, password, email address, postal address, phone number, financial account information such as a credit card number, birthday, city of birth, demographics information, and any other information you choose to give us.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Information We Collect When You Use Our Services
            </h3>

            <p>
                When you use our services, we may collect information about the services you use and how you use them. This information includes:
            </p>

            <ul>
                <li>
                    <span class="font-bold">Purchasing Information</span> – We may collect information about the products you buy, including where you buy products, how frequently you buy products, and the rewards earned due to a purchase.
                </li>

                <li>
                    <span class="font-bold">Device and Website Use Information</span> – When you install one of our mobile applications or use a computer, tablet, smart phone or other device to access our websites or purchase products or services via our online properties, we may collect information about the device and how you use it.
                </li>
            </ul>

            <p>
                This information may include the type of device, your operating system, your browser (for example, whether you used Internet Explorer, Firefox, Safari, Chrome or another browser), your internet service provider, your domain name, your internet protocol (IP) address, your device identifier (or UDID), the date and time that you accessed our service, the website that referred you to our website, the web pages you requested, the date and time of those requests, and the subject of the ads you click or scroll over. To collect this information, we use cookies, web beacons and similar technologies. <span class="font-bold">Location Information</span> – When you use a smart phone or other mobile device to access our services, use a computer to access our website or use our mobile application, we may collect information about your physical location.
            </p>

            <p class="pt-4">
                We may combine this information with other location-based information, such as your IP address and billing or postal code, to give you information about stores near you and to provide you with other services on your mobile device. We share your location information only with companies that help us provide you with services and help us understand how the services are being used. If you want to opt out of the collection of this location information, please see the section below titled “Your Choices”.
            </p>

            <ul>
                <li>
                    <span class="font-bold">In-Store Camera Information</span> – Some of our Licensees may use cameras in stores to keep employees and customers safe and to look for ways to improve their customers’ experiences. These cameras are not used these cameras to identify people except when necessary for security reasons.
                </li>
            </ul>

            <h3 class="pt-4 pb-2 standard-pheading">
                How We Use the Information We Collect
            </h3>

            <p>
                We may use the information we collect about you in a variety of ways, including:
            </p>

            <ul>
                <li>to process your purchases of products and services;</li>
                <li>to communicate with you about orders, purchases, services, accounts, programs, contests, and sweepstakes;</li>
                <li>to respond to your customer service inquiries and requests for information;</li>
                <li>to post your comments or statements on our websites;</li>
                <li>to send you personalized promotions and special offers;</li>
                <li>to inform you about our brands, products, events, or other promotional purposes;</li>
                <li>to maintain and improve our sites and the products and services we offer;</li>
                <li>to detect, prevent, or investigate security breaches or fraud; and </li>
                <li>to maintain appropriate records for internal administrative purposes.</li>
            </ul>

            <h3 class="pt-4 pb-2 standard-pheading">
                How We Share the Information We Collect
            </h3>

            <p>
                We may share your information in the following circumstances:
            </p>

            <ul>
                <li><span class="font-bold">When We Work Together</span> – We may share the collected information between DIEWERT and its respective subsidiaries, websites and affiliated companies and licensees for proper management and analysis and decision making, including decisions regarding the expansion and promotion of our product and service offering, order or customer request fulfillment, and for use by those companies for the other purposes described in this Statement.
                <li><span class="font-bold">When We Work with Service Providers</span> – We may share your information with service providers that provide us with support services, such as credit card processing, website hosting, email delivery, location mapping, postal mail processing and delivery, and analytics services. We require the companies to refrain from collecting, using and disclosing your information except when they are performing work for us or when the disclosure of your information is required by law. </li>
                <li><span class="font-bold">When We Work on Business Transactions</span> – If we become involved with a merger or another situation involving the transfer of some or all of our business assets, we may share your information with business entities or people involved in the negotiation or transfer. </li>
                <li><span class="font-bold">When Sharing Helps Us Protect Lawful Interests</span> – We may disclose your information if we believe that the disclosure is required by law, if we believe that the disclosure is necessary to enforce our agreements or policies, or if we believe that the disclosure will help us protect the rights, property, or safety of DIEWERT or our customers or partners. </li>
                <li><span class="font-bold">When We Share with Other Companies for Marketing Purposes</span> – We may share your information with service providers to use your information to provide you with promotions and special offers. We also may share anonymous, aggregated statistics about your use of our websites and services with other companies. </li>
                <li><span class="font-bold">When You Give Consent</span> – We may share information about you with other companies if you give us permission or direct us to share the information. </li>
                <li><span class="font-bold">When the Information Does Not Identify You</span> – We may share your information in a way that does not directly identify you. For example, we may combine information about you with information about other people and share the combined information in a way that does not link your information to you. </li>
                <li><span class="font-bold">When You Post on Our Websites</span> – If you post information on a blog or another part of our websites, the information that you post may be seen by other visitors to our websites, including your post information and your user name. </li>
            </ul>

            <h3 class="pt-4 pb-2 standard-pheading">
                How We May Allow Others to Collect Your Information
            </h3>

            <p>
                When you use our websites or other services, we may allow third parties to collect information about you by setting their own cookies, web beacons and other similar technologies on our websites. The information collected by third parties may include the type of device, your operating system, your browser, your internet service provider, your domain name, your internet protocol (IP) address, the date and time that you accessed our service, the website that referred you to our website, the web pages you requested, the date and time of those requests, and the subject of the ads you click or scroll over.
            </p>

            <p class="pt-4">
                We allow third parties to collect this information on our websites for the following purposes:
            </p>

            <ul>
                <li><span class="font-bold">To Display Ads for Products or Services</span> – We allow some advertising companies to collect information in order to display ads on our websites that are most relevant to you. These third parties may also collect anonymous information about visitors to our websites to place them into market segments to display ads on other websites believed to be relevant to each market segment. This is known as “online behavioural advertising.” Please see “Your Choices” below for more information about opting out of these targeted display ads and controlling the use of cookies, web beacons, and similar technologies.
                <li><span class="font-bold">To Collect Information on How Our Websites and Services Are Used</span> – We allow certain service providers to use the information collected on our websites and services to help us learn about our audience and how people use our websites. The companies that use this information for this purpose do not match the information to individual users. In other words, statistical information collected by third parties regarding website usage or performance is not matched or linked to you. </li>
                <li><span class="font-bold">To Make the Services of Other Companies Work on Our Websites</span> – We allow companies to use special technologies to make certain parts of our websites work. For example, we allow Adobe to set and enable special cookies that are necessary to deliver video content for Adobe Flash Player. These cookies are called Flash cookies. </li>
                <li><span class="font-bold">To Link Your Activity on Our Websites to Your Social Networks</span> – We have added certain features to our websites that allow social networks (such as Facebook, Twitter, and Google+) to track the activities of their members or collect certain information about website visitors. These features may include technologies called “plug-ins” and “widgets.” Plug-ins create a direct link between two websites, and widgets are interactive mini-programs that provide other companies’ services on our websites. If you are concerned about the way a social network is tracking your activity, please contact the social network or review its privacy policy. For example, you can review Facebook’s data-use policy at <a href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>. </li>
            </ul>

            <h3 class="pt-4 pb-2 standard-pheading">
                Cookies, Web Beacons and Similar Technologies
            </h3>

            <p>
                We and others may use a variety of technologies to collect information about your device and use of our services. These technologies include cookies and web beacons:
            </p>

            <p class="pt-4">
                <span class="font-bold">Cookies</span> – Cookies are small data files that are sent from a website’s server and are stored on your device’s hard drive either for only the duration of your visit (“session cookies”) or for a fixed period of time (“persistent cookies”). Cookies contain information that can later be read by a web server. Most web browsers automatically accept cookies, but you can change your browser settings to give you the choice to accept a cookie or reject cookies altogether. For more information about changing your browser settings, please see the section of this Statement titled, “Your Choices”.
            </p>

            <p class="pt-4">
                We mainly use cookies for the following purposes:
            </p>

            <ul>
                <li><span class="font-bold">Website Functionality</span> – Some cookies (considered “strictly necessary”) are required to allow you to access and use our websites. Without these cookies, our websites do not work properly.</li>
                <li><span class="font-bold">Performance Monitoring</span> – Some cookies (considered “performance” cookies) help us analyze and estimate traffic on our website. They show us how visitors interact with our website, whether there are any errors, which pages are not used often, which pages take a long time to load, which pages users tend to visit and in what order. These cookies do not collect any information that could identify you and are only used to help us improve how our website works and understand user interests. </li>
                <li><span class="font-bold">User Convenience</span> – Some cookies (considered “functionality” cookies) remember information to save you the trouble of entering information every time you visit or use a particular website. For example, a cookie may remember your username to save you time when you log in to your account. </li>
                <li><span class="font-bold">Marketing</span> – Some cookies (considered “targeting or advertising” cookies) are used to tailor your experience on our website by controlling the promotions, advertisements and other marketing messages that may appear when you visit or use our website and help us learn which services you are using and how you are accessing information about us. We may use this information to personalize your visit to a website or to send you relevant promotions. </li>
                <li><span class="font-bold">Web Beacons</span> – Web beacons are small, transparent images that are embedded in web pages, applications, and emails that are sometimes called “clear gifs,” “single pixel gifs”, “page tags” or “web bugs.” We use web beacons to track the web pages you visit, to test the effectiveness of our marketing, and to find out if an email has been opened and acted on. </li>
            </ul>

            <h3 class="pt-4 pb-2 standard-pheading">
                Your Choices
            </h3>

            <h3 class="pt-4 pb-2 standard-pheading">
                Promotional Communication Choices
            </h3>

            <p>
                You can opt out of receiving promotional emails, text messages, telephone calls, and mailings by informing us of your preference at the time you sign up for an DIEWERT website user account, modifying your promotional preferences online in your account’s profile management section, or adjusting your text and notification preferences in your mobile device’s settings or by following the opt-out instructions in the promotional emails we send you.
            </p>

            <p class="pt-4">
                Alternatively, you may let us know that you do not wish to receive some or all of these promotional communications by emailing us at info@diewert.org.
            </p>

            <p class="pt-4">
                Please note that if you opt out of receiving promotional communications from us, we may still send you non-promotional communications, including emails about your accounts or purchases.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Information Collection Choices
            </h3>

            <p>
                You can also make choices about the information we collect about you:
            </p>

            <ul>
                <li><span class="font-bold">Location Information</span> – When you use a smart phone or other mobile device to access our websites, we may collect information about your physical location only if (a) “location services” for the mobile application is enabled; or (b) the permissions in the mobile device allow communication of this information. If you do not want us to collect your location information, you can opt out of sharing this information by changing the relevant preferences and permissions in your mobile device.</li>
                <li><span class="font-bold">Cookies</span> – If you want to reject cookies, you must take action to select the appropriate settings in your browser. Each browser’s cookie control is a little different, but the most common browsers (Internet Explorer, Chrome, Firefox, and Safari) have a preference or option you can select so the browser notifies you if a site wants to set a cookie and typically provides an option to accept or reject the cookie before the cookie is set. If you choose to remove or reject cookies, it will affect many features or services on our websites. If you want to learn the correct way to modify your cookie settings, please use the Help menu in your browser.. </li>
            </ul>

            <p>
                In addition to adjusting the appropriate settings in your browser, many advertising companies that may collect anonymous information for advertising targeting purposes are also members of the Digital Advertising Alliance or the Network Advertising Initiative, both of which provide an opt-out of advertisement targeting from their members located at their respective websites at <a href="www.AboutAds.info">www.AboutAds.info</a> and <a href="www.networkadvertising.org">www.networkadvertising.org</a>.
            </p>

            <ul>
                <li><span class="font-bold">Flash Cookies</span> – We allow Adobe to set and enable special cookies that are necessary to deliver video content for Adobe Flash Player. You cannot remove Flash cookies simply by changing your browser settings. If you would like to limit the websites that can store information in Flash cookies on your device, you must visit the Adobe website: <a class="break-words" href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html</a>.</li>
            </ul>

            <p>
                <span class="font-bold">“Do Not Track” Technology</span> – Some newer web browsers have a “Do Not Track” preference that transmits a “Do Not Track” header to the websites you visit with information indicating that you do not want your activity to be tracked.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Accessing or Changing Your Information or Deleting Your Account
            </h3>

            <p>
                Under the Personal Information and Electronic Documents Act and related provincial legislation, you have the right, under certain circumstances, to access information we hold about you. Any such access request must be writing and provided to us at the email or postal addresses described in the “Contact Us” section below. Any such access request may be subject to a small statutory fee to meet our costs in providing you with details of the information we hold about you.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Changing Your Information or Deleting Your Account
            </h3>

            <p>
                If you want to change your information, cancel your account, or stop us from using your information, please contact us as described in the “Contact Us” section, below. We will make every effort to promptly respond to your request. We will retain your information for as long as your account is active or as needed to provide you services. If you ask us to delete your account, we generally retain and use your account information only as long as necessary to fulfill a business or law enforcement need.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                How We Protect Your Information
            </h3>

            <p>
                DIEWERT protects your information using technical, physical, and administrative security measures to reduce the risk of loss, misuse, unauthorized access, disclosure or modification of your information. Some of our safeguards include firewalls, data encryption, physical access controls, and administrative informational controls. When you transmit highly sensitive information (such as a credit card number) through our website or in one of our mobile applications, we encrypt the transmission of that information using the Secure Sockets Layer (SSL) protocol. While we have employed security technologies and procedures to assist safeguarding your personal information, no system or network can be guaranteed to be 100% secure.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Storing and Processing Your Information
            </h3>

            <p>
                The information you provide us is stored and processed on servers owned by DIEWERT and other companies in Canada and the United States. Regardless of the company or person who processes your information and where it is processed, we will take steps to protect your information in accordance with applicable data protection laws and this Statement.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Use by Minors
            </h3>

            <p>
                We do not intend for our websites or online services to be used by anyone under the age of 13. If you are a parent or guardian and believe we may have collected information about a child, please contact us as described in the “Contact Us” section, below.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Changes to This Privacy Statement
            </h3>

            <p>
                This Statement went into effect on the date noted at the top of this webpage. We may update this Statement from time to time. If we make material changes, we will post the updated Statement on this page and change the date at the top of this webpage. We encourage you to look for updates and changes to this Statement by checking this date at the top of this webpage. We will notify you of any modifications to this Statement that might materially affect the way we use or disclose your personal information prior to the change becoming effective by means of a message on this website.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Contact Us
            </h3>

            <p>
                We welcome your questions, comments and concerns about privacy. You may contact us at info@diewert.org to ask DIEWERT a privacy question or correct personal information found in your account profile, mobile application, or another DIEWERT owned digital property.
            </p>

        </div>
    </main>
</template>

<script>
export default {
    name: 'PrivacyPolicy',

    props: {},

    data() {
        return {};
    },

    computed: {},

    methods: {},

};
</script>
